import React from "react";
import { useTitle, useLogout } from "@pankod/refine-core";
import { Icons, Menu, useMenu } from "@pankod/refine-antd";

import "./styles.css";


/*
{menuItems.map(({ icon, route, label }) => (
    <Menu.Item key={route} icon={icon}>
        <Link to={route ?? ""}>{label}</Link>
    </Menu.Item>
))}
*/

export const CustomSider = () => {
    const Title = useTitle();
    const { selectedKey } = useMenu();

    const { mutate: logout } = useLogout();

    return (
        <>
            { Title && <Title collapsed={false} /> }

            <Menu selectedKeys={[selectedKey]} mode="horizontal" style={{ borderBottom: 'none', textAlign: 'right' }} >

                <Menu.Item
                    key="logout"
                    onClick={() => logout()}
                    icon={ <Icons.LogoutOutlined /> }
                    style={{ float: 'right', position: 'absolute', right: '1.5rem' }}
                >
                    Cerrar sesi&oacute;n
                </Menu.Item>

            </Menu>
        </>
    );
};
