import axios from 'axios';
import { useApiUrl } from "@pankod/refine-core";


const requestLogin = (user, apiUrl) => {

    const params = {
        email: user.username,
        password: user.password
    }


	const config = {
		headers: {
			'Content-Type': 'application/json',
		}
	}

	return axios.post(`${ apiUrl }/login.php`, params, config);

}


const validateToken = (token, apiUrl) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }

    return axios.get(`${ apiUrl }/validateToken`, config);

}


export { requestLogin, validateToken };
